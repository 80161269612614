
.winner-modal .modal-content {
  @apply bg-gray-800 text-white;
}

.last-called-number-circle {
  @apply w-48 h-48 rounded-full border-8 relative;
}

.last-called-number-circle::before {
  content: '';
  @apply absolute top-2 left-2 right-2 bottom-2 rounded-full border-2;
}

.last-five-numbers {
  @apply w-32 h-32 rounded-full border-4 relative flex items-center justify-center;
}

.last-five-numbers::before {
  content: '';
  @apply absolute top-2 left-2 right-2 bottom-2 rounded-full border-2;
}

.loading-overlay {
  @apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center;
}

.loading-spinner {
  @apply w-16 h-16 border-4 border-white border-t-transparent rounded-full animate-spin;
}

.countdown-animation {
  @apply animate-pulse;
}

.animate-scale-in {
  animation: scaleIn 0.5s ease-in-out;
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.last-called-numbers-container {
  @apply flex overflow-x-auto ml-auto;
  flex-direction: row-reverse;
}

.last-called-numbers-container::-webkit-scrollbar {
  @apply h-2;
}

.last-called-numbers-container::-webkit-scrollbar-track {
  @apply bg-gray-800;
}

.last-called-numbers-container::-webkit-scrollbar-thumb {
  @apply bg-gray-600 rounded;
}

.last-called-numbers-container::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-500;
}

.last-called-number-circle {
  @apply w-40 h-40;
}

.last-five-numbers {
  @apply w-32 h-32;
}